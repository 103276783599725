const config = {
	CDN_URL: undefined,
	CSP_VIOLATION_REPORT_URI: "http://ec2-35-85-44-168.us-west-2.compute.amazonaws.com:5002/report/ops_portal",
	LIA_CO_PILOT_CONFIG: {
		URL: 'lia-copilot.sae.shipsy.io',
	},
AES_ENCRYPTION_KEY: "8b7e156228aed2a6abf7152985cf4f9c",
"AUTH_RSA_PUBLIC_KEY": "-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA8U+fRUkt2fV+HiYLPRvVZUH6QHL/PU60+EfKX3A6p9tMc/Ntg2N1B9aBXXjZA4gIFcRO5w6PpzWA7dqrzKQDyIdNtExjkaeA8KLLUm+JE5IN5P14yknCqgb6AtJB3aFWD2VipXH/U4tLaEona7AIpf4mzy6EWZdmC2c3kbb0dW5WQg1yemO+P8JpExvNpAu8fKwZWq1so4hGgR7paYCsaH+qqCy8h5wu8mSHgWldQfJtgGomEBDu5P91ouA59ri6NVeXMCSJMfDvYgW5XiBbCo5kHgtE+0cvtmTA3w58Mr4I6qlJM2teJkPEWZvDLdMlOBaH3M6cH1DR0xcHhkB8cwIDAQAB-----END PUBLIC KEY-----",
	OIDC_SELF_BASE_URL: '.portal.sae.shipsy.io',
	SSO_REDIRECT_BASE_URL: 'https://dashboardlogin.shipsy.io',
	USERPILOT_TOKEN: "NX-0ec2ed6c",
	USERPILOT_BLACKLISTED_ORGS: ["qwiklogistics"],
	CONSIGNMENT_LABEL_URL: "https://elabeluaeapi.shipsy.io",
	API_BASE_URL: "https://projectxuaeapi.shipsy.io/api",
	RAVEN_BASE_URL: "https://projectravenuaeapi.shipsy.io/api",
	BUSINESS_BOOKING_API_URL: "https://obbv2uaeapi.shipsy.io",
	ENVIRONMENT: "production",
	LTL_BASE_URL: "https://ltluaeapi.shipsy.io",
	LTL_POLLING_FREQUENCY: 20000,
	LOG_ROCKET_CONFIG: {
		'zajel': ['ISMAIL.IBRAHIM@ZAJEL.COM', 'THUSSAIN@ZAJEL.COM', 'ROYJEE14@GMAIL.COM', 'TEK.RAJ@ZAJEL.COM', 'BUTTDANIAL609@GMAIL.COM', 'SHIPSY_SUPPORT_ARCHIT.GARG']
	},
	LOG_ROCKET_KEY: "iuqv0m/shipsy",
	INTERCOM_KEY: "ntvhrj4m",
	SHOW_BOOKING: false,
	ROUTING_PLAYGROUND_URL: "https://routingv2.shipsy.in",
	SENTRY_KEY: "https://84e3a8767fbf69ba3252907a8328c75c@sentry.shipsy.io//56",
	ANALYTICS_DATA: [
		{
			src: "https://datastudio.google.com/embed/reporting/1jWTdi57pU6dssxwnEsdP021qUoTiG0_7/page/jp8o",
			colSpan: 24,
			height: "1400px",
		},
		{
			src: "https://datastudio.google.com/embed/reporting/1Sskj3hrItAnYeFNZmvGdBbGNZrIdqFeR/page/tj6o",
			colSpan: 24,
			height: "1400px",
		},
		{
			src: "https://datastudio.google.com/embed/reporting/1Y09hOEq1m-Nsvvdoh5l9P0RUJro3zILs/page/ui2o",
			colSpan: 24,
			height: "1400px",
		},
	],
	GOOGLE_MAPS_API_KEY: "AIzaSyC1TlsvPXIS4YbZp0G9X9Wg9px-hB_gksk",
	USE_HARDCODED_MASTER_DATA: false,
	DOMESTIC_STATUS_FETCH_POLLING_FREQUENCY: 30000,
	ROUTE_OPTIMISATION_BASE_URL: "https://projectxroutingapi.shipsy.in/api",
	RENDER_PERSISTENT_IFRAME_OPS: true,
	HIDE_ADD_TO_EXISTING_TRIP_RETAIL: true,
	HIDE_RETAIL_STORE_HUB_CHANGES: false,
	HIDE_RETAIL_SCHEDULING: true,
	QUERYBUILDER_CONFIG: {
		ANALYTICS_FRONTEND_URL: "analyticsdashboard.sae.shipsy.io",
		BACKENDURL: "https://querybuilderproduaeapi.shipsy.io",
		DOWNLOAD_PAGE_POLL_INTERVAL: 10000,
	},
	API_PLAYGROUND_CONFIG: {
		URL: "https://apiplayground.shipsy.in",
	},
	FoodDashboard_DISPATCH_POLLING_FREQUENCY: 30000,
	GA_MID: "G-L6R11ZZ96S",
	ZAPSCALE_ORG_LIST: ["connect", "pepcart", "panemirates", "warehousenow", "3mplast",
		"tejcourier", "alamin", "biryanibykilo", "burgerking", "jfl", "more", "zepto", "wellness",
		"sbt", "gmggroup", "zajil", "fnp", "cabtechnologies", "dtdc", "brfglobal", "kelloggsmenat"],
	IS_ENV_PRODUCTION: true,
	GREEN_DEPLOYMENT_URLS: ["greenportal.shipsy.io"],
	DEVREV_APP_ID: "don:core:dvrv-us-1:devo/xXjPo9nF:plug_setting/1",
	FIREBASE_CONFIG: {
		apiKey: "AIzaSyBS7-u48IAJzePDpwM-nbKNXULw8kD7HL4",
		authDomain: "notification-service-418009.firebaseapp.com",
		databaseURL: "https://notification-service-418009-default-rtdb.firebaseio.com",
		projectId: "notification-service-418009",
		storageBucket: "notification-service-418009.appspot.com",
		messagingSenderId: "413910393309",
		appId: "1:413910393309:web:18279bf70b4ef30ee9d634",
		measurementId: "G-9SKNTTVQEC"
	},
	FIREBASE_VAPID_KEY: "BMJxxP73j8ok0wOvo5Ak_qked8D9oZW4-FhMsqie5JGh08HUvWcWpQKuX7vT2KIzQ9ahK7Q8ujQdcs15G7y8m4A",
	FIRESTORE_DB_ID: "notification-service-prod",
        NEWRELIC_SNIPPET: ";window.NREUM||(NREUM={});NREUM.init={privacy:{cookies_enabled:false},ajax:{deny_list:[\"bam.nr-data.net\"]}};\n\n;NREUM.loader_config={accountID:\"1549482\",trustKey:\"1549482\",agentID:\"1120291041\",licenseKey:\"789c65050f\",applicationID:\"1120291041\"};\n;NREUM.info={beacon:\"bam.nr-data.net\",errorBeacon:\"bam.nr-data.net\",licenseKey:\"789c65050f\",applicationID:\"1120291041\",sa:1};\n",
        NEWRELIC_SAMPLING: 0.1
};

module.exports.default = config;
